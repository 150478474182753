<template>
  <div class="vue-tempalte container col-sm-4">
    <form @submit.prevent="signIn">
      <h3>Sign In</h3>

      <div class="form-group">
        <label>Email address</label>
        <input
          type="email"
          v-model="email"
          class="form-control form-control-lg"
        />
      </div>

      <div class="form-group">
        <label>Password</label>
        <input
          type="password"
          v-model="password"
          class="form-control form-control-lg"
        />
      </div>

      <b-button
        type="submit"
        variant="success"
        class="btn btn-lg btn-block mt-3"
      >
        Sign In
      </b-button>

      <p class="forgot-password text-right mt-2">
        <b-button class="btn btn-dark btn-sm" @click="onClickCreateAccount">
          Create an account ?
        </b-button>
      </p>
      <p class="forgot-password text-right mb-4">
        <b-button class="btn btn-dark btn-sm" @click="onClickForgotPassword">
          Forgot password ?
        </b-button>
      </p>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    returnTo: {
      type: String,
      default: 'home'
    }
  },
  data() {
    return {
      email: '',
      password: ''
    }
  },
  computed: {
    ...mapGetters(['signedIn'])
  },
  methods: {
    ...mapActions(['logIn', 'signOut', 'setUser']),

    onClickForgotPassword() {
      this.$router.push('/forgotPassword')
    },
    onClickCreateAccount() {
      this.$router.push('/signUp')
    },
    async signIn() {
      try {
        await this.logIn({ password: this.password, email: this.email })
        if (this.signedIn) {
          this.$emit('customEvent', {
            message: 'Successfully Logged In',
            alertVariant: 'success',
            seconds: 2
          })
          this.$router.push('/home')
        } else {
          this.$emit('customEvent', {
            message: 'Failed to Log In',
            alertVariant: 'danger',
            seconds: 3
          })
        }
      } catch (error) {
        this.$emit('customEvent', {
          message: `Failed to Log In - ${error.message}`,
          alertVariant: 'danger',
          seconds: 3
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7a7a7a;
  margin: 0;
}

.forgot-password a {
  color: #2554ff;
}
label {
  font-weight: 500;
}
.container {
  height: auto;
  padding-top: 100px;
  @media (max-width: 880px) {
    padding-bottom: 5px;
  }
}
</style>
