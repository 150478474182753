<template>
  <tr class="item">
    <td data-th="Product">
      <div class="row">
        <div class="col-sm-3 d-none d-sm-block">
          <img :src="src" alt="..." class="img-fluid" />
        </div>
        <div class="col-sm-10">
          <h4 class="nomargin">{{ name }}</h4>
          <!-- <p>{{ description }}</p> -->
        </div>
      </div>
    </td>
    <td data-th="Price">${{ price }}</td>
    <td v-if="!special" data-th="Quantity">
      <integer-plusminus v-model="quantity"></integer-plusminus>
    </td>
    <td v-else data-th="Quantity">1</td>
    <td data-th="Subtotal" class="text-center">${{ total }}</td>
    <td class="actions" data-th="">
      <button class="product-remove" @click="removeItem(cartItem)">
        <h4>X</h4>
      </button>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'
import { IntegerPlusminus } from 'vue-integer-plusminus'
import { productImg } from '../constants/index'

export default {
  props: ['item'],
  components: { IntegerPlusminus },
  data: () => ({
    productImg: productImg
  }),
  computed: {
    description() {
      return this.item.description
    },
    name() {
      return this.item.name
    },
    price() {
      return this.item.price
    },
    total() {
      return this.item.total
    },
    cartItem() {
      return this.item
    },
    src() {
      return this.item.imgSrc
    },
    special() {
      return this.item.special
    },
    quantity: {
      get() {
        return this.item.quantity
      },
      set(value) {
        this.updateCart({ name: this.item.name, value: parseInt(value) })
      }
    }
  },
  methods: {
    ...mapActions(['updateCart', 'removeFromCart']),
    removeItem(value) {
      this.removeFromCart(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.product-remove {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 0;
  background-color: #e0e0e0;
  color: #fff;
  cursor: pointer;
}
.item {
  @media (max-width: 880px) {
    text-align: center;
  }
}
</style>
