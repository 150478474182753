<template>
  <button :class="btnColor">
    <slot></slot>
    <img v-if="cartIcon" :src="require('../assets/cart.svg')" alt="Cart Icon" />
  </button>
</template>

<script>
export default {
  props: ['btnColor', 'cartIcon']
}
</script>

<style scoped>
.btn {
  z-index: 1;
  border: 0;
  right: 3em;
  top: 2em;
  cursor: pointer;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: space-around;
  position: fixed;
}

.btn-small {
  padding: 0.5em 2em;
}

.btn-medium {
  padding: 0.7em 4.5em;
}

.btn-large {
  padding: 0.7em 5em;
}

.btn-info {
  background-color: #2d9cdb;
}

.btn img {
  max-width: 30px;
}
</style>
