<template>
  <div>
    <div v-show="!loading">
      <form v-if="notDone" id="payment-form">
        <form v-show="showUpdateDetails" @submit.prevent="register">
          <h4>Customer Details</h4>
          <div class="form-group">
            <h5><b>First Name</b></h5>
            <input
              type="text"
              v-model="firstName"
              class="form-control form-control-lg"
            />
          </div>
          <div class="form-group">
            <h5><b>Last Name</b></h5>
            <input
              type="text"
              v-model="lastName"
              class="form-control form-control-lg"
            />
          </div>
          <div class="form-group">
            <h5><b>Phone Number (optional)</b></h5>
            <input
              type="text"
              v-model="phone_number"
              class="form-control form-control-lg"
            />
          </div>
          <div class="form-group">
            <h5><b>Email address</b></h5>
            <input
              type="email"
              v-model="email"
              class="form-control form-control-lg"
            />
          </div>
          <h4 padding-top="10px">Shipping Address</h4>
          <div class="form-group">
            <h5 for="inputAddress"><b>Address</b></h5>
            <input
              type="text"
              class="form-control"
              v-model="address.address1"
              id="inputAddress"
              placeholder="1234 Main St"
            />
          </div>
          <div class="form-group">
            <h5 for="inputAddress2"><b>Address 2</b></h5>
            <input
              type="text"
              class="form-control"
              v-model="address.address2"
              id="inputAddress2"
              placeholder="Apartment, studio, or floor"
            />
          </div>
          <div class="form-group">
            <h5 for="inputCity"><b>City</b></h5>
            <input
              type="text"
              v-model="address.city"
              class="form-control"
              id="inputCity"
            />
          </div>
          <div class="form-row">
            <div class="form-group col-md-2">
              <h5 for="inputState"><b>State</b></h5>
              <select
                id="inputState"
                v-model="address.state"
                class="form-control"
              >
                <option
                  v-for="state in states"
                  v-bind:value="state.value"
                  v-bind:key="state.value"
                >
                  {{ state.text }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-3">
              <h5 for="inputZip"><b>Zip</b></h5>
              <input
                type="text"
                v-model="address.zipcode"
                class="form-control"
                id="inputZip"
              />
            </div>
          </div>
        </form>
        <div class="addressView" v-show="!showUpdateDetails">
          <h3>
            Shipping Details
            <b-icon
              @click="updateShippingDetails"
              icon="pencil-square"
              scale="1.5"
            ></b-icon>
          </h3>
          <h4>{{ user.name }}</h4>
          <h4>{{ user.email }}</h4>
          <h4>{{ addressParsed.address1 }} {{ addressParsed.address2 }}</h4>
          <h4>
            {{ addressParsed.city }}, {{ addressParsed.state }}
            {{ addressParsed.zipcode }}
          </h4>
        </div>
        <!-- <div id="apple-pay-button"></div> -->
        <div id="card-container">
          <h6>
            Payment secured through
            <img
              class="squareSpace"
              :src="require('../assets/squareSpace.png')"
            />
          </h6>
        </div>
        <button
          v-on:click="handlePaymentMethodSubmission"
          id="card-button"
          type="button"
        >
          Pay {{ checkoutTotal | formatMoney }}
        </button>
        <button class="cancelButton" v-on:click="cancel()">Cancel</button>
      </form>
      <div id="payment-status-container"></div>
    </div>
    <div v-show="loading" class="text-center container" align-v="center">
      <square-grid size="150px" background="#2d9cdb" duration="1s">
      </square-grid>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { SquareGrid } from 'vue-loading-spinner'
import { states } from '../constants'
import { APP_ID, LOCATION_ID } from '../config'

const appId = APP_ID
const locationId = LOCATION_ID
export default {
  name: 'Payments',
  components: { SquareGrid },
  data() {
    return {
      cardInstance: undefined,
      loading: false,
      notDone: true,
      updateDetailsClicked: false,
      email: '',
      address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: ''
      },
      firstName: '',
      lastName: '',
      phone_number: '',
      states: states
    }
  },
  async mounted() {
    if (!window.Square) {
      throw new Error('Square.js failed to load properly')
    }
    const payments = await window.Square.payments(appId, locationId)
    try {
      this.cardInstance = await payments.card()
      this.cardInstance.attach('#card-container')
    } catch (e) {
      console.error('Initializing Card failed', e)
      return
    }
  },
  computed: {
    ...mapGetters([
      'checkoutTotal',
      'getCart',
      'getSpecialCart',
      'cartValue',
      'signedIn',
      'user'
    ]),
    addressParsed() {
      return this.user.address ? JSON.parse(this.user.address) : this.address
    },
    showUpdateDetails() {
      return !this.signedIn && !this.updateDetailsClicked
    },
    freeShipping() {
      return this.cartValue >= 35
    },
    specialCart() {
      return this.getSpecialCart.filter((item) => item !== null)
    }
  },
  methods: {
    ...mapActions(['createOrder', 'submitOrder', 'emptyCart']),
    cancel() {
      this.$emit('cancel')
    },
    updateShippingDetails() {
      this.updateDetailsClicked = true
    },
    async handlePaymentMethodSubmission(event) {
      this.loading = true
      event.preventDefault()
      const cardButton = document.getElementById('card-button')
      try {
        // disable the submit button as we await tokenization and make a
        // payment request.
        cardButton.disabled = true
        const token = await this.tokenize(this.cardInstance)
        const paymentResults = await this.createPayment(token)
        this.loading = false

        this.displayPaymentResults('SUCCESS')
        console.debug('Payment Success', paymentResults)
        this.emptyCart()
        this.$router.push({
          name: 'OrderConfirmation',
          params: { email: this.email }
        })
      } catch (e) {
        this.loading = false
        cardButton.disabled = false
        this.$emit('customEvent', {
          message: 'Payment Failed',
          alertVariant: 'danger',
          seconds: 1
        })
        this.displayPaymentResults('FAILURE')
        console.error(e.message)
      }
    },
    displayPaymentResults(status) {
      const statusContainer = document.getElementById(
        'payment-status-container'
      )
      if (status === 'SUCCESS') {
        this.notDone = false
        statusContainer.classList.remove('is-failure')
        statusContainer.classList.add('is-success')
      } else {
        statusContainer.classList.remove('is-success')
        statusContainer.classList.add('is-failure')
      }

      statusContainer.style.visibility = 'visible'
    },
    async tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize()
      if (tokenResult.status === 'OK') {
        return tokenResult.token
      } else {
        let errorMessage = `Tokenization failed-status: ${tokenResult.status}`
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`
        }
        throw new Error(errorMessage)
      }
    },
    async createPayment(token) {
      const items = []
      Object.entries(this.getCart).forEach(([, value]) => {
        items.push({
          quantity: value.quantity.toString(),
          basePriceMoney: {
            amount: value.price * 100,
            currency: 'USD'
          },
          itemType: 'ITEM',
          name: value.name
        })
      })
      this.specialCart.forEach((value) => {
        items.push({
          quantity: value.quantity.toString(),
          basePriceMoney: {
            amount: value.price * 100,
            currency: 'USD'
          },
          itemType: 'ITEM',
          name: value.name,
          note: `
              Coffee Choices:
              ${value.specialDetails.coffeeOne} & ${value.specialDetails.coffeeTwo}
              Gift Message:
              '${value.individualSpecialDetails.message}'\n
              Recipient Address:\n
              ${value.individualSpecialDetails.recipientAddress.firstName} ${value.individualSpecialDetails.recipientAddress.lastName}
               ${value.individualSpecialDetails.recipientAddress.address1}
               ${value.individualSpecialDetails.recipientAddress.address2},
               ${value.individualSpecialDetails.recipientAddress.city},
               ${value.individualSpecialDetails.recipientAddress.state},
               ${value.individualSpecialDetails.recipientAddress.zipcode}
            `
        })
      })
      const orderBody = {
        locationId: locationId,
        address: {
          addressLine1: this.address.address1,
          addressLine2: this.address.address2,
          country: 'US',
          administrativeDistrictLevel1: this.address.state,
          firstName: this.firstName,
          lastName: this.lastName,
          locality: this.address.city,
          postalCode: this.address.zipcode
        },
        freeShipping: this.freeShipping,
        email: this.email,
        phoneNumber: this.phone_number,
        fullName: this.firstName + ' ' + this.lastName,
        items: items
      }
      const orderResponse = await this.createOrder(orderBody)
      const paymentResponse = await this.submitOrder({
        locationId: locationId,
        sourceId: token,
        amount: orderResponse.totalMoney.amount,
        orderId: orderResponse.id,
        email: this.email,
        firstName: this.firstName,
        items: items
      })
      if (paymentResponse.ok) {
        return paymentResponse
      }
      const errorBody = await paymentResponse.text()
      throw new Error(errorBody)
    }
  },
  created() {}
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}
.loader {
  align-content: center;
}
.addressView {
  text-align: center;
}
.squareSpace {
  max-width: 150px;
}
#apple-pay-button {
  height: 48px;
  width: 100%;
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;
  -apple-pay-button-style: black;
}

#payment-form {
  max-width: 550px;
  min-width: 300px;
  margin: auto;
}

.buyer-inputs {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  border: none;
  margin: 0;
  padding: 0;
}

#card-container {
  margin-top: 45px;
  /* this height depends on the size of the container element */
  /* We transition from a single row to double row at 485px */
  /* Settting this min-height minimizes the impact of the card form loading */
  min-height: 90px;
}

#gift-card-container {
  margin-top: 45px;
  min-height: 90px;
}

@media screen and (max-width: 500px) {
  #card-container {
    min-height: 140px;
  }
}

#ach-button {
  margin-top: 20px;
}

#landing-page-layout {
  width: 80%;
  margin: 150px auto;
  max-width: 1000px;
}

#its-working {
  color: #737373;
}

#example-container {
  width: 100%;
  border: 1px solid #b3b3b3;
  padding: 48px;
  margin: 32px 0;
  border-radius: 12px;
}

#example-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

h3 {
  margin: 0;
  padding-bottom: 10px;
}

p {
  line-height: 24px;
}

label {
  font-size: 12px;
  width: 100%;
}

input {
  padding: 12px;
  width: 100%;
  border-radius: 5px;
  border-width: 1px;
  margin-top: 10px;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

input:focus {
  border: 1px solid #006aff;
}

button {
  color: #ffffff;
  background-color: #006aff;
  border-radius: 5px;
  cursor: pointer;
  border-style: none;
  user-select: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding: 12px;
  margin-bottom: 12px;
  width: 100%;
  box-shadow: 1px;
}

.cancelButton {
  background-color: #ff0000;
}

button:active {
  background-color: rgb(0, 85, 204);
}

button:disabled {
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.3);
}

#payment-status-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  border-radius: 50px;
  margin: 0 auto;
  width: 225px;
  height: 48px;
  visibility: hidden;
}

#payment-status-container.missing-credentials {
  width: 350px;
}

#payment-status-container.is-success:before {
  content: '';
  background-color: #00b23b;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7071 6.70711C12.0968 6.31744 12.0978 5.68597 11.7093 5.29509C11.3208 4.90422 10.6894 4.90128 10.2973 5.28852L11 6C10.2973 5.28852 10.2973 5.28853 10.2973 5.28856L10.2971 5.28866L10.2967 5.28908L10.2951 5.29071L10.2886 5.29714L10.2632 5.32224L10.166 5.41826L9.81199 5.76861C9.51475 6.06294 9.10795 6.46627 8.66977 6.90213C8.11075 7.4582 7.49643 8.07141 6.99329 8.57908L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E");
  mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7071 6.70711C12.0968 6.31744 12.0978 5.68597 11.7093 5.29509C11.3208 4.90422 10.6894 4.90128 10.2973 5.28852L11 6C10.2973 5.28852 10.2973 5.28853 10.2973 5.28856L10.2971 5.28866L10.2967 5.28908L10.2951 5.29071L10.2886 5.29714L10.2632 5.32224L10.166 5.41826L9.81199 5.76861C9.51475 6.06294 9.10795 6.46627 8.66977 6.90213C8.11075 7.4582 7.49643 8.07141 6.99329 8.57908L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E");
}

#payment-status-container.is-success:after {
  content: 'Payment successful';
  font-size: 14px;
  line-height: 16px;
}

#payment-status-container.is-failure:before {
  content: '';
  background-color: #cc0023;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
  mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
}

#payment-status-container.is-failure:after {
  content: 'Payment failed';
  font-size: 14px;
  line-height: 16px;
}

#payment-status-container.missing-credentials:before {
  content: '';
  background-color: #cc0023;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
  mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
}

#payment-status-container.missing-credentials:after {
  content: 'applicationId and/or locationId is incorrect';
  font-size: 14px;
  line-height: 16px;
}
</style>
