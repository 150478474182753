<template>
  <b-container fluid class="text-center" align-v="center">
    <div class="container col-sm-3">
      <ul class="list-group">
        <li class="list-group-item">
          Subtotal ({{ itemsInCart }} {{ 'item' | pluralize(itemsInCart) }}):
          {{ cartValue | formatMoney }}
          <em v-if="productDiscount"><b> (30% OFF applied)</b></em>
        </li>
        <li class="list-group-item">
          <span v-if="!freeShipping"
            >Standard Shipping: {{ shipping | formatMoney }}</span
          >
          <em v-if="freeShipping">Free Shipping</em>
        </li>
        <li v-if="!freeShipping" class="list-group-item">
          <span> {{ (35 - cartValue) | formatMoney }}</span>
          away from free U.S. shipping!
        </li>
        <li class="list-group-item">
          <strong>Total:</strong>
          <strong v-if="!totalDiscount">
            {{ checkoutTotal | formatMoney }}</strong
          >
          <span v-if="totalDiscount">
            <strike> {{ (cartValue + 100) | formatMoney }}</strike>
            <strong> {{ totalWithDiscount | formatMoney }}</strong>
          </span>
        </li>
      </ul>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ShoppingCartSummary',
  data: () => {
    return {
      totalDiscount: false,
      shipping: 8.75,
      productDiscount: false
    }
  },
  computed: {
    ...mapGetters(['itemsInCart', 'cartValue', 'checkoutTotal']),
    totalWithDiscount() {
      let total = this.$store.getters.total
      return total > 0 ? this.total : 0
    },
    freeShipping() {
      return this.cartValue >= 35
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.container {
  width: 40%;
  height: auto;
  padding-bottom: 20px;
  @media (max-width: 880px) {
    width: 80%;
    padding-bottom: 5px;
  }
}
</style>
