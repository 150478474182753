<template>
  <b-container fluid class="text-center home" align-v="center">
    <h1 v-if="isMobile">Welcome to the Mackinac Coffee Company</h1>
    <div class="container">
      <img alt="Vue logo" :src="require('../assets/logo-transparent.png')" />
    </div>
    <b-row>
      <b-col>
        <h1 v-if="!isMobile">Welcome to the Mackinac Coffee Company</h1>
        <h2>We understand humanity runs best on good coffee.</h2>
      </b-col>
    </b-row>
    <b-row>
      <div class="button text-center">
        <b-button pill @click="returnToShopping"
          ><h3>Shop Coffees</h3></b-button
        >
      </div>
      <!-- <div class="specialButton text-center">
        <img
          class="heart"
          @click="returnToSpecials"
          :src="require('../assets/valentinesday-button.png')"
        />
      </div> -->
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'HomePage',
  computed: {
    ...mapGetters(['isMobile'])
  },
  methods: {
    returnToShopping() {
      this.$router.push('/shopNow')
    },
    returnToSpecials() {
      this.$router.push('/special')
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  padding-top: 100px;
  @media (max-width: 880px) {
    padding-top: 80px;
  }
}
.heart {
  max-width: 300px;
  &:hover {
    transform: scale(1.1);
  }
  @media (max-width: 880px) {
    max-width: 200px;
  }
}
.container {
  width: 25%;
  height: auto;
  padding-bottom: 20px;
  @media (max-width: 880px) {
    width: 80%;
    padding-bottom: 5px;
  }
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
h1 {
  font: normal 2rem 'Open Sans', sans-serif;
  @media (max-width: 880px) {
    font: normal 2rem 'Open Sans', sans-serif;
  }
}
h2 {
  font: normal 1.5rem 'Open Sans', sans-serif;
  @media (max-width: 880px) {
    font: normal 1.2rem 'Open Sans', sans-serif;
  }
}
h3 {
  font: normal 1.5rem 'Open Sans', sans-serif;
  margin-bottom: 0;
}
.button {
  padding-top: 30px;
}
.specialButton {
  padding-top: 30px;
}
</style>
