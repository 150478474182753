<template>
  <b-container fluid class="text-center container" align-v="center">
    <div class="product">
      <img :src="imgSrc" alt="..." class="img-fluid" />
    </div>
    <div class="description">
      <b-row>
        <h1>{{ productName }}</h1>
        <h3 v-if="valentines">${{ price }}</h3>
      </b-row>
      <b-row class="dropdown">
        <h4>
          {{
            productDesc[productName] ? productDesc[productName] : description
          }}
        </h4>
      </b-row>
      <b-row v-if="!addedToCard">
        <b-col v-if="!isMobile && !valentines">
          <b-dropdown
            size="lg"
            v-model="quantityToAdd"
            :text="dropDownText"
            class="m-md-2 dropdown"
          >
            <b-dropdown-item @click="quantityToAdd = i" v-for="i in 10" :key="i"
              >({{ i }}) - {{ (i * price) | formatMoney }}</b-dropdown-item
            >
          </b-dropdown>
          <b-dropdown size="lg" text="12oz Bag" class="m-md-2 dropdown">
            <b-dropdown-item>12oz Bag</b-dropdown-item>
          </b-dropdown>
        </b-col>
        <div v-else>
          <b-row v-if="!valentines">
            <b-dropdown
              size="lg"
              v-model="quantityToAdd"
              :text="dropDownText"
              class="m-md-2 dropdown"
            >
              <b-dropdown-item
                @click="quantityToAdd = i"
                v-for="i in 10"
                :key="i"
                >({{ i }}) - {{ (i * price) | formatMoney }}</b-dropdown-item
              >
            </b-dropdown>
          </b-row>
        </div>
      </b-row>
      <b-row v-if="valentines && !addedToCard">
        <b-dropdown
          size="lg"
          v-model="coffeeOne"
          :text="coffeeOneText"
          class="m-md-2 dropdown"
        >
          <b-dropdown-item
            v-for="product in products"
            @click="coffeeOne = product.name"
            :key="product.name"
            >{{ product.name }} - 12oz</b-dropdown-item
          >
        </b-dropdown>
      </b-row>
      <b-row v-if="valentines && selectTwo && !addedToCard">
        <b-dropdown
          size="lg"
          v-model="coffeeTwo"
          :text="coffeeTwoText"
          class="m-md-2 dropdown"
        >
          <b-dropdown-item
            v-for="product in products"
            @click="coffeeTwo = product.name"
            :key="product.name"
            >{{ product.name }} - 12oz</b-dropdown-item
          >
        </b-dropdown>
      </b-row>
      <div v-if="valentines && !addedToCard" class="recipientDetails">
        <b-row v-if="valentines">
          <label class="label" for="textarea">Gift Message</label>
          <textarea
            class="message"
            name="textarea"
            id="textarea"
            required=""
            v-model="message.text"
            :maxlength="message.maxlength"
          ></textarea>
          <span class="counter"
            >{{ message.text.length }} / {{ message.maxlength }}</span
          >
        </b-row>
        <form>
          <h4>Recipient Details</h4>
          <div class="form-group">
            <h5><b>First Name</b></h5>
            <input
              type="text"
              v-model="recipientAddress.firstName"
              class="form-control form-control-lg"
            />
          </div>
          <div class="form-group">
            <h5><b>Last Name</b></h5>
            <input
              type="text"
              v-model="recipientAddress.lastName"
              class="form-control form-control-lg"
            />
          </div>
          <div class="form-group">
            <h5 for="inputAddress"><b>Address</b></h5>
            <input
              type="text"
              class="form-control"
              v-model="recipientAddress.address1"
              id="inputAddress"
              placeholder="1234 Main St"
            />
          </div>
          <div class="form-group">
            <h5 for="inputAddress2"><b>Address 2</b></h5>
            <input
              type="text"
              class="form-control"
              v-model="recipientAddress.address2"
              id="inputAddress2"
              placeholder="Apartment, studio, or floor"
            />
          </div>
          <div class="form-group">
            <h5 for="inputCity"><b>City</b></h5>
            <input
              type="text"
              v-model="recipientAddress.city"
              class="form-control"
              id="inputCity"
            />
          </div>
          <div class="form-row">
            <div class="form-group col-md-2">
              <h5 for="inputState"><b>State</b></h5>
              <select
                id="inputState"
                v-model="recipientAddress.state"
                class="form-control"
              >
                <option
                  v-for="state in states"
                  v-bind:value="state.value"
                  v-bind:key="state.value"
                >
                  {{ state.text }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-3">
              <h5 for="inputZip"><b>Zip</b></h5>
              <input
                type="text"
                v-model="recipientAddress.zipcode"
                class="form-control"
                id="inputZip"
              />
            </div>
          </div>
        </form>
      </div>
      <b-row v-if="!addedToCard">
        <div class="text-center addToCart">
          <b-button @click="add"><h2>Add to Cart</h2></b-button>
        </div>
      </b-row>
      <b-row v-else>
        <div v-if="valentines" class="text-center addAnotherGift">
          <b-button @click="addAnother"><h2>Add Another Gift Box</h2></b-button>
        </div>
        <div class="text-center addToCart">
          <b-button @click="shopNow"><h2>Continue Shopping</h2></b-button>
        </div>
        <div class="text-center addToCart">
          <b-button @click="viewCart"><h2>View Cart</h2></b-button>
        </div>
      </b-row>
    </div>
    <disclosure-links></disclosure-links>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { productDesc } from '../constants/index'
import { states } from '../constants'
import accounting from 'accounting'
import DisclosureLinks from './DisclosureLinks.vue'

export default {
  components: { DisclosureLinks },
  name: 'ProductInfo',
  data: () => ({
    // productImg: productImg,
    productDesc: productDesc,
    quantityToAdd: 1,
    addedToCard: false,
    coffeeOne: null,
    coffeeTwo: null,
    message: {
      text: '',
      maxlength: 75
    },
    recipientAddress: {
      address1: null,
      address2: null,
      city: null,
      firstName: null,
      lastName: null,
      state: null,
      zipcode: null
    },
    states: states
  }),
  computed: {
    ...mapGetters(['getCart', 'isMobile', 'listProducts', 'currentItem']),

    dropDownText() {
      return `(${this.quantityToAdd}) - ${accounting.formatMoney(
        this.quantityToAdd * this.price
      )}`
    },
    coffeeOneText() {
      return this.coffeeOne ? this.coffeeOne : 'Select a Coffee'
    },
    coffeeTwoText() {
      return this.coffeeTwo ? this.coffeeTwo : 'Select Another Coffee'
    },
    description() {
      return this.currentItem.description
    },
    imgSrc() {
      return this.currentItem.imgSrc
    },
    productName() {
      return this.currentItem.name
    },
    price() {
      return this.currentItem.price
    },
    total() {
      return this.currentItem.total
    },
    valentines() {
      return this.currentItem.valentines
    },
    products() {
      return this.listProducts.products.filter((product) => !product.soldOut)
    },
    selectTwo() {
      return this.currentItem.selectTwo
    },
    specialName() {
      let name = `${this.productName} w/ \n${this.coffeeOne}`
      if (this.selectTwo) name += ` & ${this.coffeeTwo}`
      return name
    }
  },
  methods: {
    ...mapActions([
      'updateCart',
      'addToCart',
      'addToCartSpecial',
      'removeFromCart'
    ]),
    updateQuantity(value) {
      this.quantity = value
    },
    removeItem(value) {
      this.removeFromCart(value)
    },
    addAnother() {
      this.addedToCard = false
      this.coffeeOne = null
      this.coffeeTwo = null
      this.message = {
        text: '',
        maxlength: 75
      }
      this.recipientAddress = {
        address1: null,
        address2: null,
        city: null,
        firstName: null,
        lastName: null,
        state: null,
        zipcode: null
      }
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    },
    shopNow() {
      this.$router.push('/shopNow')
    },
    viewCart() {
      this.$router.push('/shoppingCart')
    },
    verifySpecial() {
      if (
        this.recipientAddress.address1 === null ||
        this.recipientAddress.city === null ||
        this.recipientAddress.firstName === null ||
        this.recipientAddress.lastName === null ||
        this.recipientAddress.state === null ||
        this.recipientAddress.zipcode === null
      )
        return false
      else if (!this.coffeeOne) return false
      else if (this.selectTwo && !this.coffeeTwo) return false
      return true
    },
    add() {
      if (!this.valentines) {
        this.addToCart({
          quantityToAdd: this.quantityToAdd,
          ...this.currentItem
        })
        this.$emit('customEvent', {
          message: 'Successfully added to cart',
          alertVariant: 'success',
          seconds: 1
        })
        this.addedToCard = true
      } else if (!this.verifySpecial()) {
        this.$emit('customEvent', {
          message: 'Please Fill Out Recipient Address',
          alertVariant: 'danger',
          seconds: 1
        })
      } else {
        this.addToCartSpecial({
          ...this.currentItem,
          name: this.specialName,
          special: true,
          specialDetails: {
            coffeeOne: this.listProducts.products[this.coffeeOne],
            coffeeTwo: this.listProducts.products[this.coffeeTwo] | null
          },
          individualSpecialDetails: {
            message: this.message.text,
            recipientAddress: this.recipientAddress
          }
        })
        this.$emit('customEvent', {
          message: 'Successfully added to cart',
          alertVariant: 'success',
          seconds: 1
        })
        this.addedToCard = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.product {
  width: 400px;
  display: inline-block;
  padding-top: 50px;
  height: auto;
  padding-bottom: 20px;
  justify-content: center;

  @media (max-width: 880px) {
    width: 300px;
    padding-top: 20px;
  }
}
.message {
  max-width: 80%;
  margin: 0 0 0 120px;
  @media (max-width: 880px) {
    margin: 0 0 0 35px;
  }
}
.description {
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0);
  border-radius: 25px;
  padding: 10px;
  margin-bottom: 30px;
}
h1 {
  padding-bottom: 10px;
}
.dropdown {
  padding-bottom: 10px;
}
.recipientDetails {
  margin: 20px auto;
}
.addToCart {
  padding-top: 10px;
  h2 {
    margin-bottom: 0.1rem;
  }
}
</style>
