<template>
  <div class="vue-tempalte container col-sm-4">
    <form>
      <h2>Welcome, {{ user.name }}</h2>
      <h5>Customer Details</h5>
      <div class="form-group">
        <h3>
          Full Name
          <b-icon @click="updateName" icon="pencil-square" scale="1.5"></b-icon>
        </h3>
        <h4 v-if="!showUpdateName">
          {{ user.name }}
          <!-- <b-button @click="updateName" class="btn btn-dark btn-sm">
            Change Name?
          </b-button> -->
        </h4>
        <div v-if="showUpdateName">
          <input
            type="text"
            :value="user.name"
            class="form-control form-control-lg"
          />
        </div>
      </div>

      <div class="form-group">
        <h3>
          Email address <b-icon icon="pencil-square" scale="1.5"></b-icon>
        </h3>
        <h4>
          {{ user.email }}
        </h4>
      </div>

      <div class="form-group">
        <h3>
          Password
          <b-icon
            @click="updatePassword"
            icon="pencil-square"
            scale="1.5"
          ></b-icon>
        </h3>
        <h4 v-if="!showUpdatePassword">
          ********
          <!-- <b-button @click="updatePassword" class="btn btn-dark btn-sm">
            Reset Password?
          </b-button> -->
        </h4>
        <div v-if="showUpdatePassword">
          <input
            type="text"
            :value="password"
            placeholder="Enter Old Password"
            class="form-control form-control-lg passwordForm"
          />
          <input
            type="text"
            :value="newPassword"
            placeholder="Enter New Password"
            class="form-control form-control-lg"
          />
        </div>
      </div>
      <h3>
        Shipping Address
        <b-icon
          @click="updateAddress"
          icon="pencil-square"
          scale="1.5"
        ></b-icon>
      </h3>
      <div class="addressView" v-if="!showUpdateAddress">
        <h4>{{ addressParsed.address1 }} {{ addressParsed.address2 }}</h4>
        <h4>
          {{ addressParsed.city }}, {{ addressParsed.state }}
          {{ addressParsed.zipcode }}
        </h4>
      </div>
      <div v-else>
        <div class="form-group">
          <label for="inputAddress">Address</label>
          <input
            type="text"
            class="form-control"
            v-model="address.address1"
            id="inputAddress"
            placeholder="1234 Main St"
          />
        </div>
        <div class="form-group">
          <label for="inputAddress2">Address 2</label>
          <input
            type="text"
            class="form-control"
            v-model="address.address2"
            id="inputAddress2"
            placeholder="Apartment, studio, or floor"
          />
        </div>
        <div class="form-group">
          <label for="inputCity">City</label>
          <input
            type="text"
            v-model="address.city"
            class="form-control"
            id="inputCity"
          />
        </div>
        <div class="form-row">
          <div class="form-group">
            <label for="inputState">State</label>
            <select
              id="inputState"
              v-model="address.state"
              class="form-control"
            >
              <option
                v-for="state in states"
                v-bind:value="state.value"
                v-bind:key="state.value"
              >
                {{ state.text }}
              </option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label for="inputZip">Zip</label>
            <input
              type="text"
              v-model="address.zipcode"
              class="form-control"
              id="inputZip"
            />
          </div>
        </div>
      </div>
      <div>
        <b-button
          v-if="showSaveChanges"
          type="submit"
          variant="success"
          class="btn btn-lg btn-block mt-3"
        >
          Save Changes
        </b-button>
      </div>
      <b-button
        @click="signCustomerOut"
        class="btn btn-dark btn-lg btn-block mt-3"
      >
        Sign Out ?
      </b-button>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { states } from '../constants'

export default {
  name: 'Profile',
  components: {},
  computed: {
    ...mapGetters(['user']),
    addressParsed() {
      return JSON.parse(this.user.address)
    }
  },
  data() {
    return {
      showUpdateName: false,
      showUpdatePassword: false,
      showSaveChanges: false,
      showUpdateAddress: false,
      password: '',
      newPassword: '',
      username: '',
      address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: ''
      },
      name: '',
      phone_number: '',
      notRegistered: true,
      states: states
    }
  },
  methods: {
    ...mapActions(['signOut']),
    updateName() {
      this.showUpdateName = !this.showUpdateName
      this.showSaveChanges = true
    },
    updateEmail() {
      this.showUpdateEmail = !this.showUpdateEmail
      this.showSaveChanges = true
    },
    updatePassword() {
      this.showUpdatePassword = !this.showUpdatePassword
      this.showSaveChanges = true
    },
    updateAddress() {
      this.showUpdateAddress = !this.showUpdateAddress
      this.showSaveChanges = true
    },
    async signCustomerOut() {
      await this.signOut()
      if (!this.signedIn) {
        this.$emit('customEvent', {
          message: 'Successfully Signed Out',
          alertVariant: 'success',
          seconds: 2
        })
        this.$router.push('/home')
      } else {
        this.$emit('customEvent', {
          message: 'Failed to Logout, try again please',
          alertVariant: 'danger',
          seconds: 3
        })
      }
    },
    onClick() {
      this.$router.push('/home')
    }
  }
}
</script>
<style lang="scss" scoped>
label {
  font-weight: 500;
}
.centerView {
  text-align: center;
}
.container {
  height: auto;
  @media (max-width: 880px) {
    padding-bottom: 5px;
  }
}
.passwordForm {
  margin-bottom: 10px;
}
h5 {
  margin-top: 20px;
}
</style>
