import axios from 'axios'
import { isProduction } from '../config'

export default () => {
  return axios.create({
    baseURL: isProduction
      ? `https://x5httyaa7f.execute-api.us-east-1.amazonaws.com/dev`
      : `http://localhost:8081`
  })
}
