<template>
  <b-container fluid class="vh-100 text-center" align-v="center">
    <div class="container">
      <img alt="Vue logo" :src="require('../assets/logo-transparent.png')" />
    </div>
    <b-row>
      <b-col>
        <h1>Our Products</h1>
      </b-col>
    </b-row>
    <b-row class="description">
      <h4>
        Every cup of our coffee starts with meticulous care. We have searched
        for the finest roasted coffee beans while respecting the environment,
        Rainforest Alliance, and the fair trade coffee industries.
      </h4>
    </b-row>
    <div class="products">
      <div
        v-for="product in newProducts"
        :key="product.name"
        class="box"
        v-on:click="showDetails(product)"
      >
        <img :src="productImg[product.name].imgSrc" :alt="product.name" />
        <!-- <img :src="require('../assets/carmel-blue.png')" :alt="product.name" /> -->
        <h5>{{ product.name }}</h5>
      </div>
    </div>
    <b-row>
      <b-col>
        <h4>Free Shipping on Orders $35+</h4>
      </b-col>
    </b-row>
    <disclosure-links></disclosure-links>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { productImg } from '../constants/index'
import DisclosureLinks from './DisclosureLinks.vue'

export default {
  name: 'ShopNowPage',
  components: { DisclosureLinks },
  data: () => ({
    productImg: productImg,
    newProducts: null
  }),
  async created() {
    this.newProducts = this.listProducts.products
    await this.getProducts()
    this.newProducts = this.listProducts.products
  },
  computed: {
    ...mapGetters(['getCart', 'isMobile', 'listProducts']),
    cart() {
      return this.getCart
    }
  },
  methods: {
    ...mapActions(['addToCart', 'getProducts', 'setCurrentProduct']),
    northHarbor() {
      window.open('https://mynorthharbor.com/', '_blank')
    },
    add(e) {
      this.addToCart(e)
      if (this.isMobile)
        this.$emit('customEvent', {
          message: 'Successfully added to cart',
          alertVariant: 'success',
          seconds: 1
        })
    },
    disclosure(type) {
      this.$router.push({
        name: 'Disclaimers',
        params: { disclosure: type }
      })
    },
    showDetails(product) {
      if (!product.soldOut) {
        this.setCurrentProduct({
          imgSrc: productImg[product.name].imgSrc,
          ...product
        })
        this.$router.push({
          name: 'ProductInfo'
        })
      } else {
        this.$emit('customEvent', {
          message: 'Sorry, item is out of stock.',
          alertVariant: 'danger',
          seconds: 1
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 20%;
  height: auto;
  padding-bottom: 20px;
  display: flex;

  justify-content: center;
  @media (max-width: 880px) {
    width: 80%;
    padding-bottom: 5px;
  }
}
.plus {
  line-height: 0px !important;
}
.card-group .card {
  max-width: 25%;
}
img {
  width: 110%;
  height: 110%;
  object-fit: cover;
}
.shineLogo {
  width: 10%;
  @media (max-width: 880px) {
    width: 15%;
  }
}
a {
  font-size: 1.5rem;
  text-decoration: none;
}
.shineDonation {
  cursor: pointer;
  padding-bottom: 25px;
}
b-container {
  padding-bottom: 20px;
}
h1 {
  font: normal 2rem 'Open Sans', sans-serif;
  padding: 10px 0 20px 0;
  @media (max-width: 880px) {
    font: normal 2rem 'Open Sans', sans-serif;
  }
}
h2 {
  font: normal 1.5rem 'Open Sans', sans-serif;
  @media (max-width: 880px) {
    font: normal 1.2rem 'Open Sans', sans-serif;
  }
}
.products {
  padding-bottom: 50px;
  padding-top: 20px;
}
.description {
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0);
  border-radius: 25px;
  padding: 10px;
}
.box {
  width: 230px;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0);
  display: inline-block;
  border-radius: 25px;
  margin: 10px 10px;
  position: relative;
  &:hover {
    transform: scale(1.05);
  }
  cursor: pointer;
  button {
    width: 50px;
    height: 50px;
    background: #ed277f;
    color: #ffffff;
    border-radius: 25px;
    text-align: center;
    line-height: 50px;
    font-size: 1.4rem;
    position: absolute;
    right: 20px;
    top: 150px;
    box-shadow: 0 0 4px 2px rgba(80, 80, 80, 0.1);
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      transform: scale(1.1);
    }
  }
  p {
    // margin-left: 20px;
    margin-bottom: 5px;
  }
}
</style>
