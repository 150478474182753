import Vue from 'vue'
import VueRouter from 'vue-router'
import CartCheckout from '../components/CartCheckout'
import ForgotPassword from '../components/ForgotPassword'
import HomePage from '../components/HomePage'
import Login from '../components/Login'
import Profile from '../components/Profile'
import ShopNowPage from '../components/ShopNowPage'
import Signup from '../components/Signup'
import ContactUs from '../components/ContactUs'
import store from '../store/index'
import OrderConfirmation from '../components/OrderConfirmation'
import Product from '../components/Product'
import Disclaimers from '../components/Disclaimers'
// import Special from '../components/Special'
Vue.use(VueRouter)

export const routes = [
  {
    path: '/home',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: ContactUs
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/shopNow',
    name: 'ShopNowPage',
    component: ShopNowPage
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/shoppingCart',
    name: 'CartCheckout',
    component: CartCheckout
  },
  {
    path: '/orderConfirmation',
    name: 'OrderConfirmation',
    component: OrderConfirmation,
    props: true
  },
  {
    path: '/productInfo',
    name: 'ProductInfo',
    component: Product,
    props: true
  },
  // {
  //   path: '/special',
  //   name: 'Special',
  //   component: Special,
  //   props: true
  // },
  {
    path: '/Disclaimers',
    name: 'Disclaimers',
    component: Disclaimers,
    props: true
  },
  {
    path: '*',
    redirect: '/home'
  }
]
export function createRouter() {
  const router = new VueRouter({
    mode: 'history',
    routes
  })

  const waitForStorageToBeReady = async (to, from, next) => {
    await store.restored
    next()
  }
  router.beforeEach(waitForStorageToBeReady)
  return router
}
