<template>
  <div id="navigation">
    <ul>
      <li v-if="signedIn" @click="onClick({ value: '/profile' })">
        <b-icon class="h2" icon="person-fill"></b-icon>{{ user.name }}
      </li>
      <li @click="onClick({ value: '/home' })">Home</li>
      <!-- <li @click="onClick({ value: '/special' })">
        <img
          class="heart"
          :src="require('../assets/coffeeHeartSmall.png')"
        />Valentine's Specials
      </li> -->
      <li @click="onClick({ value: '/shopNow' })">Shop Coffees</li>
      <li @click="onClick({ value: '/contactUs' })">Contact Us</li>
      <li v-if="!signedIn" @click="onClick({ value: '/signUp' })">Sign Up</li>
      <li v-if="!signedIn" @click="onClick({ value: '/logIn' })">
        <b-icon class="h2" icon="person-fill"></b-icon>Login
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Navigation',
  computed: {
    ...mapGetters(['signedIn', 'user'])
  },
  data() {
    return {}
  },
  methods: {
    onClick(e) {
      if (e.value !== this.$router.currentRoute.path) this.$router.push(e.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.heart {
  max-width: 3rem;
  &:hover {
    transform: scale(1.1);
  }
}
#navigation {
  display: flex;
  width: 100%;
  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0 20px 0 0;

    li {
      font-size: 1.7rem;
      padding: 2px 15px;
      cursor: pointer;

      &:hover {
        color: #7ca971;
      }
    }
  }
  .b-icon.bi {
    vertical-align: middle;
  }
}
</style>
