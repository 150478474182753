import api from './api'

async function submitPayment(payload) {
  return await api().post('/process-payment', payload)
}

async function createOrder(payload) {
  return await api().post('/create-order', payload)
}

async function getProducts() {
  return await api().get('/get-products')
}

export { createOrder, getProducts, submitPayment }
