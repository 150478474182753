/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:67bf5e1c-b94a-4ec4-85a6-7703514ac4ef",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_lCsYy8PaG",
    "aws_user_pools_web_client_id": "1r0barjd7cf5rp6j270snt7enl",
    "oauth": {}
};


export default awsmobile;
