<template>
  <div class="vue-tempalte container col-sm-4">
    <h2>Order Sent!</h2>
    <h3>
      You will recieve a order confirmation and receipt to the email you
      provided: {{ email }}
    </h3>
    <button class="btn btn-success" @click="returnToShopping">
      Back to Shopping
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPaymentForm: false
    }
  },
  props: ['email'],
  components: {},
  methods: {
    returnToShopping() {
      this.$router.push('/shopNow')
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  height: auto;
  padding-top: 150px;
  text-align: center;
  @media (max-width: 880px) {
    overflow-wrap: break-word;
    padding-bottom: 5px;
  }
}
.btn {
  margin-top: 20px;
}
.btn-success {
  color: rgb(0, 0, 0);
  &:hover {
    color: rgb(0, 0, 0);
  }
}
</style>
