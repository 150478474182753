<template>
  <div>
    <div v-if="itemsInCart > 0" class="container table-responsive">
      <table id="cart" class="table table-hover table-sm">
        <thead>
          <tr>
            <th style="width: 50%">Product</th>
            <th style="width: 10%">Price</th>
            <th style="width: 8%">Quantity</th>
            <th style="width: 22%" class="text-center">Subtotal</th>
            <th style="width: 10%"></th>
          </tr>
        </thead>
        <transition-group name="list-shopping-cart" tag="tbody">
          <CartItem
            v-for="cartItem in getCart"
            :item="cartItem"
            :key="cartItem.name"
          ></CartItem>
          <CartItem
            v-for="cartItem in specialCart"
            :item="cartItem"
            :key="cartItem.name"
          ></CartItem>
        </transition-group>
      </table>
    </div>
    <div>
      <ShoppingCartSummary v-if="itemsInCart > 0" />
      <h3 v-else>Your cart is empty!</h3>
      <div class="mt-3 text-center">
        <button
          v-if="itemsInCart > 0 && !showPaymentForm"
          class="btn btn-success"
          @click="checkout"
        >
          Checkout
        </button>
        <button
          v-else-if="!(itemsInCart > 0) && !showPaymentForm"
          class="btn btn-success"
          @click="returnToShopping"
        >
          Back to Shopping
        </button>
      </div>
    </div>
    <Payments
      @cancel="cancelPayment"
      v-if="showPaymentForm && itemsInCart > 0"
    ></Payments>
    <div class="mt-3 text-center">
      <disclosure-links></disclosure-links>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CartItem from './CartItem'
import ShoppingCartSummary from './ShoppingCartSummary'
import Payments from './Payments'
import DisclosureLinks from './DisclosureLinks.vue'
export default {
  computed: {
    ...mapGetters(['getCart', 'getSpecialCart', 'cartValue', 'itemsInCart']),
    specialCart() {
      return this.getSpecialCart.filter((item) => item !== null)
    }
  },
  data() {
    return {
      showPaymentForm: false
    }
  },
  components: {
    CartItem,
    ShoppingCartSummary,
    Payments,
    DisclosureLinks
  },
  methods: {
    ...mapActions([]),
    returnToShopping() {
      this.$router.push('/shopNow')
    },
    checkout() {
      this.showPaymentForm = true
    },
    cancelPayment() {
      this.showPaymentForm = false
    },
    disclosure(type) {
      this.$router.push({
        name: 'Disclaimers',
        params: { disclosure: type }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.list-shopping-cart-leave-active {
  transition: all 0.4s;
}
.container {
  @media (max-width: 880px) {
    margin-top: 25px;
  }
}
.list-shopping-cart-leave-to {
  opacity: 0;
  transform: translateX(50px);
}
.table-sm {
  font-size: 0.875rem;
  ::v-deep h4 {
    font-size: 1.25rem;
  }
}
h3 {
  padding-top: 100px;
  text-align: center;
}
.btn-success {
  color: rgb(0, 0, 0);
  &:hover {
    color: rgb(0, 0, 0);
  }
}
</style>
