<template>
  <div id="navigation-mobile">
    <ul>
      <li @click="onClick({ value: '/shoppingCart' })">
        Cart
        <img :src="require('../assets/cart.svg')" alt="Cart Icon" />
      </li>
      <li @click="onClick({ value: '/home' })">Home</li>
      <!-- <li @click="onClick({ value: '/special' })">
        Valentine's Specials<img
          class="heart"
          :src="require('../assets/coffeeHeartSmall.png')"
        />
      </li> -->
      <li @click="onClick({ value: '/shopNow' })">Shop Coffees</li>
      <li @click="onClick({ value: '/contactUs' })">Contact Us</li>
      <li v-if="!signedIn" @click="onClick({ value: '/signUp' })">Sign Up</li>
      <li v-if="!signedIn" @click="onClick({ value: '/logIn' })">
        <b-icon class="h1" icon="person-fill"></b-icon> Login
      </li>
      <li v-if="signedIn" @click="onClick({ value: '/profile' })">
        <b-icon class="h1" icon="person-fill"></b-icon>{{ user.name }}
      </li>
    </ul>
    <div class="social-media">
      <!-- <i class="fab fa-twitter"></i> -->
      <i class="fab fa-instagram"></i>
      <!-- <i class="fab fa-pinterest"></i> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NavigationMobile',
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getCart', 'signedIn', 'user']),
    itemsInCart() {
      let count = 0
      Object.values(this.getCart).forEach((key) => {
        count += key.quantity
      })
      return count
    }
  },
  methods: {
    onClick(e) {
      this.$emit('setShowNav')
      if (e.value !== this.$router.currentRoute.path) this.$router.push(e.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.heart {
  max-width: 4rem;
}
#navigation-mobile {
  padding: 20px 0 0 0;

  ul {
    list-style: none;
    width: 200px;
    padding-left: 40px;
    padding-top: 20px;
    li {
      color: #fff;
      font-size: 2rem;
      margin-bottom: 20px;
      cursor: pointer;

      &:hover {
        color: #111;
      }
    }
  }
  img {
    max-width: 30px;
  }
  .social-media {
    position: absolute;
    left: 40px;
    // bottom: 100px;

    i {
      margin: 0 20px 0 0;
      color: #fff;
      font-size: 2rem;
    }
  }
  .b-icon.bi {
    vertical-align: middle;
  }
}
</style>
