<template>
  <div class="vue-tempalte container">
    <form @submit.prevent="confirm">
      <h3>Confirm your email</h3>
      <p>We sent a confirmation code to your email, please enter your code</p>

      <div class="form-group">
        <label>Confirmation Code</label>
        <input
          type="text"
          v-model="confirmationCode"
          class="form-control form-control-lg"
        />
      </div>

      <b-button type="submit" class="btn btn-dark btn-lg btn-block mt-3">
        Confirm and Sign In
      </b-button>
    </form>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      confirmationCode: ''
    }
  },
  computed: {
    ...mapGetters(['signedIn'])
  },
  props: {
    username: {
      type: String,
      required: true
    },
    password: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions(['logIn']),
    async confirm() {
      try {
        await Auth.confirmSignUp(this.username, this.confirmationCode)
        await this.logIn({ password: this.password, email: this.email })
        if (this.signedIn) {
          this.$parent.$emit('customEvent', {
            message: 'Successfully Confirmed Email',
            alertVariant: 'success',
            seconds: 2
          })
          this.$router.push('/home')
        } else {
          this.$parent.$emit('customEvent', {
            message: 'Failed to Log In',
            alertVariant: 'danger',
            seconds: 3
          })
        }
      } catch (error) {
        this.$parent.$emit('customEvent', {
          message: `Failed to Confirm Account - ${error.message}`,
          alertVariant: 'danger',
          seconds: 3
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
label {
  font-weight: 500;
}
.container {
  height: auto;
  padding-top: 100px;
  @media (max-width: 880px) {
    padding-bottom: 5px;
  }
}
h5 {
  margin-top: 10px;
}
</style>
