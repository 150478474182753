<template>
  <div class="vue-tempalte container col-sm-4">
    <h2>Sorry for the inconvinence!</h2>
    <h4>
      We are a young company, and still working out the details of our
      memberships.
    </h4>
    <h4>
      Please check back soon, we are excited for you to join our community!
    </h4>
    <form v-if="notRegistered && false" @submit.prevent="register">
      <h3>Sign Up</h3>
      <h5>Customer Details</h5>
      <div class="form-group">
        <label>Full Name</label>
        <input
          type="text"
          v-model="name"
          class="form-control form-control-lg"
        />
      </div>
      <div class="form-group">
        <label>Username</label>
        <input
          type="text"
          v-model="username"
          class="form-control form-control-lg"
        />
      </div>

      <div class="form-group">
        <label>Phone Number</label>
        <input
          type="text"
          v-model="phone_number"
          class="form-control form-control-lg"
        />
      </div>

      <div class="form-group">
        <label>Email address</label>
        <input
          type="email"
          v-model="email"
          class="form-control form-control-lg"
        />
      </div>

      <div class="form-group">
        <label>Password</label>
        <input
          type="password"
          v-model="password"
          class="form-control form-control-lg"
        />
      </div>
      <h5>Shipping Address</h5>
      <div class="form-group">
        <label for="inputAddress">Address</label>
        <input
          type="text"
          class="form-control"
          v-model="address.address1"
          id="inputAddress"
          placeholder="1234 Main St"
        />
      </div>
      <div class="form-group">
        <label for="inputAddress2">Address 2</label>
        <input
          type="text"
          class="form-control"
          v-model="address.address2"
          id="inputAddress2"
          placeholder="Apartment, studio, or floor"
        />
      </div>
      <div class="form-group">
        <label for="inputCity">City</label>
        <input
          type="text"
          v-model="address.city"
          class="form-control"
          id="inputCity"
        />
      </div>
      <div class="form-row">
        <div class="form-group col-md-2">
          <label for="inputState">State</label>
          <select id="inputState" v-model="address.state" class="form-control">
            <option
              v-for="state in states"
              v-bind:value="state.value"
              v-bind:key="state.value"
            >
              {{ state.text }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <label for="inputZip">Zip</label>
          <input
            type="text"
            v-model="address.zipcode"
            class="form-control"
            id="inputZip"
          />
        </div>
      </div>

      <b-button
        type="submit"
        variant="success"
        class="btn btn-lg btn-block mt-3"
      >
        Sign Up
      </b-button>

      <p class="forgot-password text-right">
        <b-button class="btn btn-dark btn-sm" @click="onClick">
          Already registered sign in?
        </b-button>
      </p>
    </form>
    <ConfirmEmail
      v-else-if="false"
      :username="username"
      :password="password"
      :email="email"
    ></ConfirmEmail>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'
import { states } from '../constants'
import ConfirmEmail from './ConfirmEmail'
export default {
  name: 'Signup',
  components: {
    ConfirmEmail
  },
  data() {
    return {
      email: '',
      password: '',
      username: '',
      address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: ''
      },
      name: '',
      phone_number: '',
      notRegistered: true,
      confirmationCode: '',
      states: states
    }
  },
  props: {
    returnTo: {
      type: String,
      default: 'home'
    }
  },
  methods: {
    onClick() {
      this.$router.push('/logIn')
    },
    async register() {
      try {
        await Auth.signUp({
          username: this.username,
          password: this.password,
          attributes: {
            email: this.email,
            address: JSON.stringify(this.address),
            name: this.name,
            phone_number: this.phoneNumber
          }
        })
        this.notRegistered = false
      } catch (error) {
        this.$emit('customEvent', {
          message: `Failed to Sign Up - ${error.message}`,
          alertVariant: 'danger',
          seconds: 4
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7a7a7a;
  margin: 0;
}

.forgot-password a {
  color: #2554ff;
}
label {
  font-weight: 500;
}
.container {
  padding-top: 150px;
  height: auto;
  @media (max-width: 880px) {
    padding-bottom: 5px;
  }
}
h5 {
  margin-top: 10px;
}
</style>
