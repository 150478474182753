<template>
  <div class="vue-tempalte container col-sm-4">
    <form v-if="!codeSent" @submit.prevent="forgotPassword">
      <h3>Forgot Password</h3>

      <div class="form-group">
        <label>Email address</label>
        <input
          type="email"
          v-model="email"
          class="form-control form-control-lg"
        />
      </div>

      <button type="submit" class="btn btn-dark btn-lg btn-block mt-3">
        Reset password
      </button>
    </form>
    <form v-else @submit.prevent="forgotPasswordSubmit">
      <h3>New Password</h3>
      <p>
        We sent a code to your email to reset your password, please enter your
        code
      </p>

      <div class="form-group">
        <label>Rest Code</label>
        <input
          type="text"
          v-model="code"
          class="form-control form-control-lg"
        />
      </div>
      <div class="form-group">
        <label>New Password</label>
        <input
          type="password"
          v-model="new_password"
          class="form-control form-control-lg"
        />
      </div>

      <button type="submit" class="btn btn-dark btn-lg btn-block mt-3">
        Reset Password
      </button>
      <button class="btn btn-dark btn-lg btn-block mt-3" @click="resendButton">
        Resend Code
      </button>
    </form>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'
import { mapActions } from 'vuex'

export default {
  props: {
    returnTo: {
      type: String,
      default: 'home'
    }
  },
  data() {
    return {
      email: '',
      codeSent: false,
      code: '',
      new_password: ''
    }
  },
  methods: {
    ...mapActions(['logIn']),
    resendButton() {
      this.codeSent = false
    },
    async forgotPassword() {
      try {
        await Auth.forgotPassword(this.email)
        this.codeSent = true
      } catch (error) {
        console.log('error sending forgot password code', error)
      }
    },
    async forgotPasswordSubmit() {
      try {
        await Auth.forgotPasswordSubmit(
          this.email,
          this.code,
          this.new_password
        )
        this.logIn({ password: this.new_password, email: this.email })
        this.$router.push('/home')
      } catch (error) {
        console.log('error sending forgot password code', error)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
label {
  font-weight: 500;
}
.container {
  height: auto;
  padding-top: 100px;
  @media (max-width: 880px) {
    padding-bottom: 5px;
  }
}
</style>
