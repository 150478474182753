import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import Crypto from 'crypto-js'
import Cookie from 'js-cookie'
import { uuid } from 'vue-uuid'
import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'
import { createOrder, getProducts, submitPayment } from '../services/square'

Vue.use(Vuex)

const cookieName = 'MccSession'

const storageKey = 'storageKey'

// Get the encryption token from cookie or generate a new one.
const encryptionToken = Cookie.get(cookieName) || uuid.v4()

// Store the encryption token in a secure cookie.
Cookie.set(cookieName, encryptionToken, { secure: true, expires: 180 })

const vuexLocal = new VuexPersistence({
  storage: {
    getItem: () => {
      // Get the store from local storage.
      const store = window.localStorage.getItem(storageKey)

      if (store) {
        try {
          // Decrypt the store retrieved from local storage
          // using our encryption token stored in cookies.
          const bytes = Crypto.AES.decrypt(store, encryptionToken)
          // console.log(JSON.parse(bytes.toString(Crypto.enc.Utf8)))
          return JSON.parse(bytes.toString(Crypto.enc.Utf8))
          // return JSON.parse(store)
        } catch (e) {
          // The store will be reset if decryption fails.
          window.localStorage.removeItem(storageKey)
        }
      }

      return null
    },
    setItem: (key, value) => {
      // Encrypt the store using our encryption token stored in cookies.
      const store = Crypto.AES.encrypt(value, encryptionToken).toString()
      // Save the encrypted store in local storage.
      return window.localStorage.setItem(storageKey, store)
    },
    removeItem: () => window.localStorage.removeItem(storageKey)
  }
})
export default new Vuex.Store({
  state: {
    cart: {},
    specialCart: [],
    specialItemsNum: 0,
    cartValue: 0.0,
    itemsInCart: 0,
    signedIn: false,
    isMobile: false,
    user: { name: null },
    paymentStatus: null,
    products: [],
    currentItem: {}
  },
  plugins: [vuexLocal.plugin],
  mutations: {
    async addToCart(state, payload) {
      if (!state.cart[payload.name]) {
        Vue.set(state.cart, payload.name, { ...payload, quantity: 0, total: 0 })
      }
      const cost =
        parseInt(state.cart[payload.name].price) *
        parseInt(payload.quantityToAdd)
      Vue.set(
        state,
        'itemsInCart',
        parseInt(parseInt(state.itemsInCart) + parseInt(payload.quantityToAdd))
      )
      Vue.set(
        state,
        'cartValue',
        parseInt(parseInt(state.cartValue) + parseInt(cost))
      )
      Vue.set(
        state.cart[payload.name],
        'quantity',
        parseInt(
          parseInt(state.cart[payload.name].quantity) +
            parseInt(payload.quantityToAdd)
        )
      )
      Vue.set(
        state.cart[payload.name],
        'total',
        parseInt(parseInt(state.cart[payload.name].total) + parseInt(cost))
      )
    },
    async addToCartSpecial(state, payload) {
      Vue.set(state.specialCart, state.specialItemsNum, {
        ...payload,
        quantity: 1,
        index: state.specialItemsNum,
        total: payload.price
      })
      console.log(`jeremy - ${JSON.stringify(state.specialCart)}`)
      Vue.set(state, 'specialItemsNum', parseInt(state.specialItemsNum + 1))
      Vue.set(
        state,
        'itemsInCart',
        parseInt(parseInt(state.itemsInCart) + parseInt(1))
      )
      Vue.set(
        state,
        'cartValue',
        parseInt(parseInt(state.cartValue) + parseInt(payload.price))
      )
    },
    async updateCart(state, payload) {
      Vue.set(
        state,
        'itemsInCart',
        parseInt(state.itemsInCart) -
          parseInt(state.cart[payload.name].quantity)
      )
      Vue.set(
        state,
        'cartValue',
        parseInt(state.cartValue) -
          parseInt(state.cart[payload.name].price) *
            parseInt(state.cart[payload.name].quantity)
      )
      if (payload.value > 0) {
        Vue.set(state.cart[payload.name], 'quantity', payload.value)
        Vue.set(
          state.cart[payload.name],
          'total',
          parseInt(state.cart[payload.name].price) *
            parseInt(state.cart[payload.name].quantity)
        )
        Vue.set(
          state,
          'itemsInCart',
          parseInt(state.itemsInCart) +
            parseInt(state.cart[payload.name].quantity)
        )
        Vue.set(
          state,
          'cartValue',
          parseInt(state.cartValue) +
            parseInt(state.cart[payload.name].price) *
              parseInt(state.cart[payload.name].quantity)
        )
      } else {
        Vue.delete(state.cart, payload.name)
      }
    },
    removeFromCart(state, payload) {
      Vue.set(
        state,
        'cartValue',
        parseInt(state.cartValue) -
          parseInt(payload.price) * parseInt(payload.quantity)
      )
      Vue.set(
        state,
        'itemsInCart',
        parseInt(state.itemsInCart) - parseInt(payload.quantity)
      )
      if (!payload.special) Vue.delete(state.cart, payload.name)
      else {
        Vue.set(state.specialCart, payload.index, null)
      }
    },
    signOut(state) {
      Vue.set(state, 'signedIn', false)
      Vue.set(state, 'user', { name: null })
    },
    signIn(state) {
      Vue.set(state, 'signedIn', true)
    },
    setUser(state, payload) {
      Vue.set(state, 'user', payload)
    },
    setMobile(state, payload) {
      Vue.set(state, 'isMobile', payload)
    },
    setPaymentStatus(state, payload) {
      Vue.set(state, 'paymentStatus', payload)
    },
    emptyCart(state) {
      Vue.set(state, 'cartValue', 0)
      Vue.set(state, 'itemsInCart', 0)
      Vue.set(state, 'cart', {})
      Vue.set(state, 'specialCart', [])
    },
    setProducts(state, payload) {
      Vue.set(state, 'products', payload)
    }
  },
  actions: {
    async getProducts({ commit }) {
      try {
        const response = await getProducts()
        commit('setProducts', { products: response.data.products })
        return {
          ok: true
        }
      } catch (error) {
        console.log(error)
      }
    },
    setCurrentProduct({ commit }, payload) {
      console.log(payload, commit)
      this.state.currentItem = payload
    },
    async createOrder({ commit }, payload) {
      console.log(commit)
      try {
        const response = await createOrder(payload)
        return {
          ...response.data.order,
          ok: true
        }
      } catch (error) {
        console.log(error)
      }
    },
    async submitOrder({ commit }, payload) {
      try {
        const response = await submitPayment(payload)
        commit('setPaymentStatus', {
          ...response.data,
          status: response.status,
          ok: true
        })
        return {
          ...response.data,
          status: response.status,
          ok: true
        }
      } catch (error) {
        console.log(error)
      }
    },
    async signOut({ commit }) {
      try {
        commit('setUser', null)
        commit('signOut')
        await Auth.signOut()
      } catch (error) {
        console.log(`error signing out - ${error.message}`)
      }
    },
    async logIn({ commit }, payload) {
      try {
        await Auth.signIn(payload.email, payload.password)
        AmplifyEventBus.$emit('authState', 'signedIn')
        const user = await Auth.currentAuthenticatedUser()
        commit('setUser', {
          ...user.attributes,
          username: user.username,
          password: payload.password
        })
        commit('signIn')
      } catch (error) {
        console.log(`error logging in - ${error.message}`)
      }
    },
    addToCart({ commit }, payload) {
      commit('addToCart', payload)
    },
    addToCartSpecial({ commit }, payload) {
      commit('addToCartSpecial', payload)
    },
    updateCart({ commit }, payload) {
      commit('updateCart', payload)
    },
    removeFromCart({ commit }, payload) {
      commit('removeFromCart', payload)
    },
    emptyCart({ commit }) {
      commit('emptyCart')
    },
    setUser({ commit }, payload) {
      commit('setUser', {
        ...payload.attributes,
        username: payload.username
      })
    },
    setMobile({ commit }, payload) {
      commit('setMobile', payload)
    }
  },
  modules: {},
  getters: {
    getCart: (state) => state.cart,
    getSpecialCart: (state) => state.specialCart,
    cartValue: (state) => state.cartValue,
    itemsInCart: (state) => state.itemsInCart,
    signedIn: (state) => state.signedIn,
    user: (state) => state.user,
    isMobile: (state) => state.isMobile,
    checkoutTotal: (state) => {
      return state.cartValue >= 35 ? state.cartValue : state.cartValue + 8.75
    },
    listProducts: (state) => state.products,
    currentItem: (state) => state.currentItem
  }
})
