<template>
  <div class="vue-tempalte container col-sm-4" id="app">
    <form v-if="!submitted" class="vue-form" @submit.prevent="submit">
      <fieldset>
        <h3 class="label" for="name">{{ title }}</h3>
        <div>
          <label class="label" for="name">Name</label>
          <input type="text" name="name" id="name" required="" v-model="name" />
        </div>
        <div>
          <label class="label" for="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            required=""
            :class="{ email, error: !email.valid }"
            v-model="email.value"
          />
        </div>
        <div>
          <label class="label" for="textarea">Message</label>
          <textarea
            class="message"
            name="textarea"
            id="textarea"
            required=""
            v-model="message.text"
            :maxlength="message.maxlength"
          ></textarea>
          <span class="counter"
            >{{ message.text.length }} / {{ message.maxlength }}</span
          >
        </div>
        <div>
          <input type="submit" value="Send Form" />
        </div>
      </fieldset>
    </form>
    <form v-else class="vue-form" @submit.prevent="submitAnother">
      <fieldset>
        <label class="label" for="name">Message Sent</label>
        <div>
          <input type="submit" value="Send Another Message" />
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
var emailRegExp =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
module.exports = {
  name: 'ContactForm',
  props: {
    title: {
      type: String,
      default: 'How May We Assist You?'
    },
    nextLine: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      name: '',
      email: {
        value: '',
        valid: true
      },
      message: {
        text: `Dear Mackinac Coffee Company,\n${this.nextLine}...`,
        maxlength: 255
      },
      submitted: false
    }
  },
  methods: {
    // submit form handler
    submit(e) {
      this.submitted = true
      e.preventDefault()
      this.axios
        .post(
          'https://d6075qe8g4.execute-api.us-east-1.amazonaws.com/prod/submit',
          {
            name: this.name,
            email: this.email.value,
            message: this.message.text
          }
        )
        .then(function (response) {
          console.log(`error - ${response} - ${JSON.stringify(response)}`)
        })
        .catch(function (error) {
          console.log(`error - ${error} - ${JSON.stringify(error)}`)
        })
    },
    // submit form handler
    submitAnother() {
      this.submitted = false
      this.message.text = `Dear Mackinac Coffee Company,\n ...`
    },
    // validate by type and value
    validate(type, value) {
      if (type === 'email') {
        this.email.valid = this.isEmail(value) ? true : false
      }
    },
    // check for valid email adress
    isEmail(value) {
      return emailRegExp.test(value)
    }
  },
  watch: {
    // watching nested property
    'email.value': function (value) {
      this.validate('email', value)
    }
  }
}
</script>

<style></style>
<style scoped>
.container {
  height: auto;
  padding-top: 100px;
  @media (max-width: 880px) {
    padding-bottom: 5px;
  }
}

#app {
  display: flex;
}

.vue-form {
  font-size: 16px;
  width: 700px;
  padding: 15px 30px;
  border-radius: 4px;
  margin: 20px auto;
}
.vue-form div {
  position: relative;
  margin: 20px 0;
}
.vue-form h4,
.vue-form .label {
  margin-bottom: 10px;
}
.vue-form .label {
  display: block;
}
.vue-form input,
.vue-form textarea,
.vue-form input[type='text'],
.vue-form input[type='email'],
.vue-form textarea {
  display: block;
  width: 100%;
  appearance: none;
}
.vue-form input[type='text'],
.vue-form input[type='email'],
.vue-form textarea {
  padding: 12px;
  border: 1px solid #cfd9db;
  background-color: #ffffff;
  border-radius: 0.25em;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}
.vue-form input[type='text']:focus,
.vue-form input[type='email']:focus,
.vue-form textarea:focus {
  outline: none;
  border-color: #2c3e50;
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.2);
}
.vue-form textarea {
  min-height: 120px;
  resize: vertical;
  overflow: auto;
}
.vue-form input[type='submit'] {
  border: none;
  background: #2c3e50;
  border-radius: 0.25em;
  padding: 12px 20px;
  color: #ffffff;
  font-weight: bold;
  float: right;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  appearance: none;
}
.no-touch .vue-form input[type='submit']:hover {
  background: #42a2e1;
}
.vue-form input[type='submit']:focus {
  outline: none;
  background: #2b3e51;
}
.vue-form input[type='submit']:active {
  transform: scale(0.9);
}
.vue-form .error-message {
  height: 35px;
  margin: 0px;
}
.vue-form .error-message p {
  background: #e94b35;
  color: #ffffff;
  font-size: 1.4rem;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 0.25em;
  padding: 16px;
}
.vue-form .error {
  border-color: #e94b35 !important;
}
.vue-form .counter {
  background-color: #ecf0f1;
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 10px;
  padding: 4px;
}
@media screen and (max-device-width: 480px) {
  .vue-form {
    margin: 0 auto;
  }
}
@-webkit-keyframes cd-bounce {
  0%,
  100% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8);
  }
}
@-moz-keyframes cd-bounce {
  0%,
  100% {
    -moz-transform: scale(1);
  }
  50% {
    -moz-transform: scale(0.8);
  }
}
@keyframes cd-bounce {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
}
</style>
