<template>
  <div id="app">
    <b-alert
      :variant="alertVariant"
      fade
      :show="dismissCountDown"
      @dismissed="dismissCountDown = 0"
      style="z-index: 2000"
      class="position-fixed fixed-top m-5 rounded-5"
      @dismiss-count-down="countDownChanged"
    >
      {{ alertMessage }}
    </b-alert>
    <NavigationMobile @setShowNav="setShowNav()" signedIn />
    <div class="content" :style="cssVars" :class="{ open: showNav }">
      <div class="top-bar">
        <div id="navigation-icon" v-if="isMobile" @click="showNav = !showNav">
          <i class="fas fa-bars"
            ><span class="btn-circle-mobile" v-if="hasProduct()">
              {{ itemsInCart }}
            </span></i
          >
        </div>
        <Navigation @setShowNav="setShowNav()" signedIn v-if="!isMobile" />
        <btn
          v-if="hasProduct() && !isMobile"
          btnColor="btn btn-small btn-info btn-popup"
          :cartIcon="true"
          @click.native="showCart()"
        >
          <span class="btn-circle" v-if="hasProduct()">
            {{ itemsInCart }}
          </span>
        </btn>
      </div>
      <transition @before-enter="scrollTop" mode="out-in" appear>
        <router-view @customEvent="showAlert($event)"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Auth } from 'aws-amplify'
import btn from './components/Btn'
import Navigation from './components/Navigation'
import NavigationMobile from './components/NavigationMobile'

export default {
  data: () => {
    return {
      showNav: false,
      isMobile: false,
      dismissSecs: 1,
      dismissCountDown: 0,
      alertMessage: '',
      alertVariant: 'success'
    }
  },
  components: {
    Navigation,
    NavigationMobile,
    btn
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },

  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  async created() {
    await this.getProducts()
    this.findUser()
  },
  computed: {
    ...mapGetters(['getCart', 'itemsInCart', 'signedIn']),
    cssVars() {
      return {
        '--height': this.isMobile ? this.$innerHeight + 'px' : '100vh'
      }
    }
  },
  methods: {
    ...mapActions(['signOut', 'setUser', 'setMobile', 'getProducts']),
    hasProduct() {
      return this.itemsInCart > 0
    },
    onResize() {
      this.isMobile = window.innerWidth < 600
      this.setMobile(this.isMobile)
    },
    showCart() {
      this.$router.push('/shoppingCart')
    },
    setShowNav() {
      this.showNav = !this.showNav
    },
    scrollTop() {
      document.getElementById('app').scrollIntoView()
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert(e) {
      this.alertVariant = e.alertVariant
      this.alertMessage = e.message
      this.dismissSecs = e.seconds
      this.dismissCountDown = this.dismissSecs
    },
    async findUser() {
      try {
        const user = await Auth.currentAuthenticatedUser()
        this.setUser(user)
      } catch (err) {
        this.signOut()
        this.setUser({ user: null })
      }
    }
  }
}
</script>

<style lang="scss" scope>
@import url('https://use.fontawesome.com/releases/v5.9.0/css/all.css');

* {
  font-size: 1.2rem;
  @media (max-width: 880px) {
    font-size: 1rem;
  }
}

body {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma;
  background-color: #946c4c;
  overflow: hidden;
}

#app {
  position: relative;
  width: calc(100% - 10px);
  height: calc(100vh - 10px);
  padding: 10px;
  color: #333;
  overflow: hidden;
  @media (max-width: 880px) {
    width: calc(100% - 1px);
    padding: 7px;
  }
}

.top-bar {
  display: flex;
  padding-bottom: 20px;
  width: 100%;
}

#navigation-icon {
  padding: 10px 10px 0px;
  margin-right: 10px;
  cursor: pointer;

  position: fixed;
  i {
    font-size: 2rem;
  }
}
.content {
  position: absolute;
  overflow: auto;
  top: 10px;
  width: calc(100% - 15px);
  height: calc(var(--height) - 25px);
  padding: 20px;
  background-image: linear-gradient(
      to bottom,
      rgba(245, 246, 252, 0.52),
      rgba(160, 82, 45, 0.73)
    ),
    url('../src/assets/background2.jpeg');
  background-size: cover;
  border-radius: 30px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  transition: 1s transform cubic-bezier(0, 0.12, 0.14, 1);
}

.open {
  transform: translateX(300px);
}

.btn-circle {
  z-index: 1;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #fff;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-circle-mobile {
  z-index: 1;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: fixed;
  top: 25px;
  left: 60px;
  background-color: rgb(236, 173, 43);
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
