// package.json sets NODE_ENV in its scripts
const isProduction = process.env.NODE_ENV === 'production'
// export secrets stored in .env.production or .env.sandbox (based on .env.example)
module.exports = {
  APP_ID: isProduction
    ? 'sq0idp-nipobOmfPHtC7JOe4V1yMQ'
    : 'sandbox-sq0idb-l2hC3U3HDZ9CvTYQCBnLnQ',
  LOCATION_ID: isProduction ? 'L310NP3CD01XX' : 'LX0AR3BRQ6STG',
  isProduction
}
