<template>
  <div class="mt-1 text-center links">
    <b-link style="font-size: 1.2rem" @click="disclosure('privacy')"
      >Privacy Policy</b-link
    >
    |
    <b-link style="font-size: 1.2rem" @click="disclosure('refund')"
      >Refund Policy</b-link
    >
    |
    <b-link style="font-size: 1.2rem" @click="disclosure('terms')"
      >Terms of Service</b-link
    >
  </div>
</template>

<script>
export default {
  data: () => ({}),
  computed: {},
  methods: {
    disclosure(type) {
      this.$router.push({
        name: 'Disclaimers',
        params: { disclosure: type }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.links {
  font-size: 1.3rem;
  padding: 10px 30px;
  // color: white;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0);
  border-radius: 15px;
  display: inline-block;
  margin: 30px 10px;
}
</style>
