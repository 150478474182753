export const productImg = {
  Hazelnut: {
    imgSrc: require('../assets/hazelnut-green.png')
  },
  'White Chocolate': {
    imgSrc: require('../assets/white-chocolate-blue-soldout.png'),
    soldOut: true
  },
  Campfire: {
    imgSrc: require('../assets/campfire-green.png')
  },
  Carmel: {
    imgSrc: require('../assets/carmel-blue.png')
  },
  Snowday: {
    imgSrc: require('../assets/snowday-blue.png')
  }
}
// export const valentinesProducts = {
//   valentinesOne: {
//     name: 'Small Gift Box',
//     imgSrc: require('../assets/valentinesday-5.png'),
//     price: 20.0,
//     valentines: true,
//     description:
//       'Customize your gift package with your choice of a 12oz bag from our assorted line of delicious coffee flavors. Paired excellently with our chocolate covered espresso beans, the perfect bite-sized energy boost. All of our Valentine’s Day specials are gift packed for delivery to your loved ones.'
//   },
//   valentinesTwo: {
//     name: 'Medium Gift Box',
//     price: 25.0,
//     valentines: true,
//     selectTwo: true,
//     imgSrc: require('../assets/valentinesday-7.png'),
//     description:
//       'Customize your gift package with a pair of 12oz bags from our assorted line of delicious coffee flavors. All of our Valentine’s Day specials are gift packed for delivery to your loved ones.'
//   },
//   valentinesThree: {
//     name: 'Large Gift Box',
//     price: 30.0,
//     valentines: true,
//     selectTwo: true,
//     imgSrc: require('../assets/valentinesday-4.png'),
//     description:
//       'Customize your gift package with a pair of 12oz bags from our assorted line of delicious coffee flavors. Paired excellently with our chocolate covered espresso beans, the perfect bite-sized energy boost. All of our Valentine’s Day specials are gift packed for delivery to your loved ones.'
//   }
// }

export const productDesc = {
  Campfire:
    'Our Campfire Coffee has a buttery nut flavor with rich vanilla notes. We locally source this coffee blend for its rich earthy taste.',
  Carmel:
    'Our Carmel blend coffee, is a light roast, deliciously salted traditional carmel flavored blend.',
  Snowday:
    'Our Snowday Coffee is inspired by the flavors of the traditional Irish coffee. Dark Chocolate and Irish Creme Flavored Coffee, blended to perfection, and then flavored to create a sweet velvety flavor, with a smooth vanilla drizzle.',
  Hazelnut:
    'Our Hazelnut blend coffee, is a light roast with 100% Arabica beans. The smooth aroma carries through to the richness of each sip.',
  Everything: ''
}

export const states = [
  {
    value: 'AK',
    text: 'Alaska'
  },
  {
    value: 'AZ',
    text: 'Arizona'
  },
  {
    value: 'AR',
    text: 'Arkansas'
  },
  {
    value: 'CA',
    text: 'California'
  },
  {
    value: 'CO',
    text: 'Colorado'
  },
  {
    value: 'CT',
    text: 'Connecticut'
  },
  {
    value: 'DE',
    text: 'Delaware'
  },
  {
    value: 'DC',
    text: 'District Of Columbia'
  },
  {
    value: 'FL',
    text: 'Florida'
  },
  {
    value: 'GA',
    text: 'Georgia'
  },
  {
    value: 'HI',
    text: 'Hawaii'
  },
  {
    value: 'ID',
    text: 'Idaho'
  },
  {
    value: 'IL',
    text: 'Illinois'
  },
  {
    value: 'IN',
    text: 'Indiana'
  },
  {
    value: 'IA',
    text: 'Iowa'
  },
  {
    value: 'KS',
    text: 'Kansas'
  },
  {
    value: 'KY',
    text: 'Kentucky'
  },
  {
    value: 'LA',
    text: 'Louisiana'
  },
  {
    value: 'ME',
    text: 'Maine'
  },
  {
    value: 'MD',
    text: 'Maryland'
  },
  {
    value: 'MA',
    text: 'Massachusetts'
  },
  {
    value: 'MI',
    text: 'Michigan'
  },
  {
    value: 'MN',
    text: 'Minnesota'
  },
  {
    value: 'MS',
    text: 'Mississippi'
  },
  {
    value: 'MO',
    text: 'Missouri'
  },
  {
    value: 'MT',
    text: 'Montana'
  },
  {
    value: 'NE',
    text: 'Nebraska'
  },
  {
    value: 'NV',
    text: 'Nevada'
  },
  {
    value: 'NH',
    text: 'New Hampshire'
  },
  {
    value: 'NJ',
    text: 'New Jersey'
  },
  {
    value: 'NM',
    text: 'New Mexico'
  },
  {
    value: 'NY',
    text: 'New York'
  },
  {
    value: 'NC',
    text: 'North Carolina'
  },
  {
    value: 'ND',
    text: 'North Dakota'
  },
  {
    value: 'OH',
    text: 'Ohio'
  },
  {
    value: 'OK',
    text: 'Oklahoma'
  },
  {
    value: 'OR',
    text: 'Oregon'
  },
  {
    value: 'PA',
    text: 'Pennsylvania'
  },
  {
    value: 'RI',
    text: 'Rhode Island'
  },
  {
    value: 'SC',
    text: 'South Carolina'
  },
  {
    value: 'SD',
    text: 'South Dakota'
  },
  {
    value: 'TN',
    text: 'Tennessee'
  },
  {
    value: 'TX',
    text: 'Texas'
  },
  {
    value: 'UT',
    text: 'Utah'
  },
  {
    value: 'VT',
    text: 'Vermont'
  },
  {
    value: 'VA',
    text: 'Virginia'
  },
  {
    value: 'WA',
    text: 'Washington'
  },
  {
    value: 'WV',
    text: 'West Virginia'
  },
  {
    value: 'WI',
    text: 'Wisconsin'
  },
  {
    value: 'WY',
    text: 'Wyoming'
  }
]
