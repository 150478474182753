import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'
import accounting from 'accounting'
import App from './App'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import pluralize from 'pluralize'
import { createRouter } from './router'
import store from './store'
import UUID from 'vue-uuid'
import Vue from 'vue'

import Amplify, * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import aws_exports from './aws-exports'

async function createApp() {
  Vue.prototype.$innerHeight = window.innerHeight

  Amplify.configure(aws_exports)
  Vue.use(AmplifyPlugin, AmplifyModules)
  Vue.use(UUID)
  Vue.use(BootstrapVue)
  Vue.use(IconsPlugin)

  Vue.config.productionTip = false

  Vue.filter('formatMoney', accounting.formatMoney)
  Vue.filter('pluralize', pluralize)
  // create router instance
  const router = createRouter()

  const app = new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount('#app')

  return { app, router }
}
createApp()
